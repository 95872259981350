<template>
  <div>
    <h2 class="mb-5">{{ title }}</h2>

    <v-card style="max-width: 900px" class="pb-5" outlined>
      <v-toolbar flat color="white">
        <v-toolbar-title>User Details</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="max-width: 900px">
        <v-form ref="userForm" v-model="valid">
          <v-text-field
            outlined
            v-model="user.firstName"
            label="First name"
            :rules="rules.firstName"
            required
          ></v-text-field>
          <v-text-field
            outlined
            v-model="user.lastName"
            label="Last name"
            :rules="rules.lastName"
            required
          ></v-text-field>
          <v-text-field
            outlined
            v-model="user.emailAddress"
            label="Email Address"
            :rules="rules.emailAddress"
            required
          ></v-text-field>
          <v-text-field
            outlined
            v-model="user.password"
            label="Password"
            :rules="rules.password"
            required
          ></v-text-field>
          <v-switch
            v-model="user.isActive"
            color="primary"
            label="Is active"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="user.id > 0"
          class="v-btn-icon"
          color="secondary"
          outlined
          @click="deleteUser"
        >
          <i class="nbi-icon nbi-trashcan error--text"></i>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn class="primary" :disabled="!valid" @click.native="save"
          >Save</v-btn
        >
      </v-card-actions>
      <confirm-dialog ref="confirm"></confirm-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import store from "../store";
import { PlatformAPI } from "../http";
var userTemplate = function (seed) {
  return {
    emailAddress: null,
    firstName: null,
    lastName: null,
    id: 0,
    isActive: true,
    ...seed,
  };
};

export default {
  data() {
    return {
      valid: false,
      rules: {
        firstName: [(v) => !!v || "First Name is required"],
        lastName: [(v) => !!v || "Last Name is required"],
        emailAddress: [(v) => !!v || "Email Address is required"],
        password: [(v) => !!v || "Password is required"],
      },
      loading: false,
      user: userTemplate(),
    };
  },
  computed: {
    title() {
      return this.$route.params["id"] == "new" ? "Add User" : "Edit User";
    },
  },
  mounted() {
    if (this.$route.params["id"] != "new") {
      var id = this.$route.params["id"];
      this.getUser(id);
    }
  },

  methods: {
    ...mapMutations(["setSnack"]),
    async getUser(id) {
      const response = await PlatformAPI.get(`/api/users/${id}`);
      this.user = response.data;
    },
    async deleteUser() {
      const confirm = await this.$refs.confirm.open("Delete", "Are you sure?", {
        color: "red",
      });

      if (!confirm) return;

      var response = await PlatformAPI.delete(`/api/users/${this.user.id}`);
      this.$router.push("/users");
      this.setSnack({ message: "User deleted succesfully", color: "success" });
    },

    async save() {
      this.$refs.userForm.validate();
      try {
        this.loading = true;

        var response = await PlatformAPI.post(`/api/users`, this.user);

        if (response.data) {
          this.$router.push("/users");
          this.setSnack({ message: "User saved", color: "success" });
        } else {
          this.setSnack({ message: "Something went wrong", color: "warning" });
        }
      } catch (err) {
        window.alert(err);
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
