<template>
  <div style="max-width:900px;">
    <h2 class="mb-5">Onboard a new device</h2>

    <v-card class="pb-5" outlined>
      <v-toolbar flat color="white">
        <v-toolbar-title> Find your device by serial number</v-toolbar-title>
      </v-toolbar>
      <OnboardDeviceWizard
        :device="device"
        v-on:device-saved="deviceSaved"
      ></OnboardDeviceWizard>

      <!-- <v-card-actions class="justify-end px-0">
        <v-btn
          class="primary"
          outlined
          :disabled="!canComplete"
          @click="completeSetup"
          >Finish</v-btn
        >
      </v-card-actions> -->
    </v-card>
  </div>
</template>
<script>
import { PlatformAPI } from "../http";
import OnboardDeviceWizard from "./onboard-device-wizard.vue";
import { mapState } from "vuex";
export default {
  components: {
    OnboardDeviceWizard,
  },
  data() {
    return {
      
      backend: null,
      organizationUnits: [],
      device: { serialNumber: "E255410", managementId: null, id: null },
      lookupDone: false,
      valid: false,
      rules: {
        serialNumber: [
          (v) => !!v || "Serial Number is required",
          (v) =>
            /[A-Za-z]{1}[0-9]{6}/i.test(v) ||
            "Please enter a serial number in the form op A123456 (1 letter, 6 digits)",
        ],
      },
    };
  },
  computed: {

    canComplete() {
      return this.device.id;
    },
  },
  async mounted() {
    await this.loadData();
    //this.backends = [];
  },
  methods: {
    async deviceSaved(device) {
      this.device = device;

      this.device.id && this.completeSetup();
    },
    async loadOrganizationUnits() {
      const ouResponse = await PlatformAPI.get("/api/ou", {
        params: { backendId: this.backend.id },
      });
      this.organizationUnits = ouResponse.data;
    },
    completeSetup() {
      this.$router.push({
        name: "system",
        params: { type: "device", id: this.device.id },
      });
    },

    async loadData() {
      let response = await PlatformAPI.get("/api/backends");
      this.backends = response.data;
    },

    async lookupDevice() {
      try {
        const response = await PlatformAPI.post(`/api/onboarding/lookup`, {
          serialNumber: this.device.serialNumber,
        });

        if (response.status == 200) {
          this.device.managementId = response.data.id;
          this.device.created_ts = response.data.created_ts;
          this.device.status = response.data.status;
        }
      } catch (err) {
        if (err.response.status == 404)
          this.device = {
            serialNumber: this.device.serialNumber,
            managementId: null,
          };
      } finally {
        this.lookupDone = true;
      }
    },
  },
};
</script>