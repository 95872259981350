<template>
  <v-card flat>
    <v-card-title v-if="country.code">
      <i class="nbi-icon nbi-folder-marker" style="font-size: 4rem"></i>
      <p class="ml-3 mt-5">{{ country.name }}</p>
    </v-card-title>

    <v-card-text>
      <p>
        This country has <b>{{ country.devices }}</b> devices configured of
        which <b>{{ country.connected }}</b> are online.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import { PlatformAPI } from "../http";

export default {
  props: ["item"],
  data() {
    return {
      country: {},
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.item) return;

      var response = await PlatformAPI.get(
        `/api/system/country/${this.item.id}`
      );
      this.country = response.data;
    },
  },
};
</script>