<template>
  <div>
    <h2 class="mb-5">{{ title }}</h2>

    <v-card style="max-width: 900px" class="pb-5" outlined>
      <v-toolbar flat color="white">
        <v-toolbar-title>Backend Details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="backend.id > 0"
          :disabled="syncing"
          outlined
          class="v-btn-icon"
          color="secondary"
          @click="syncOrganizationUnits"
        >
          <i class="nbi-icon nbi-cloud-sync" v-if="!syncing"></i>

          <v-progress-circular
            v-if="syncing"
            indeterminate
            :size="18"
            :width="2"
            color="primary"
          ></v-progress-circular>
        </v-btn>
      </v-toolbar>

      <BackendEditor
        v-bind:backend="backend"
        v-on:backend-saved="backendSaved"
      ></BackendEditor>
    </v-card>
 
  </div>
</template>

<script>
import { PlatformAPI } from "../http";
import { mapMutations } from "vuex";
import BackendEditor from "./backend-editor";
const backendTemplate = function (seed) {
  return {
    name: null,
    id: 0,
    isEnabled: true,
    clientSecret: null,
    clientKey: null,
    ...seed,
  };
};

export default {
  components: {
    BackendEditor,
  },
  data() {
    return {
      backend: backendTemplate(),
      valid: false,
      rules: {
        name: [(v) => !!v || "Name is required"],
        endpoint: [(v) => !!v || "Endpoint is required"],
      },
      loading: false,

      syncing: false,
    };
  },
  computed: {
    title() {
      return this.$route.params["id"] == "new" ? "Add Backend" : "Edit Backend";
    },
  },
  mounted() {
    if (this.$route.params["id"] != "new") {
      var id = this.$route.params["id"];
      this.getBackend(id);
    }
  },
  methods: {
    ...mapMutations(["setSnack"]),

    async backendSaved(backend) {
      this.$router.push({
        name: "backend",
        params: {
          id: backend.id,
        },
      });
    },

    async refreshKey(keyIndex) {
      const response = await PlatformAPI.post(
        `/api/backends/${this.backend.id || ""}/reset-keys`,
        { key: keyIndex }
      );
      if (keyIndex == 0) {
        this.backend.primaryAccessToken = response.data.accessToken;
      } else {
        this.backend.secondaryAccessToken = response.data.accessToken;
      }
    },

    async getBackend(id) {
      const response = await PlatformAPI.get(`/api/backends/${id}`);
      this.backend = response.data;
      //this.backend.apiKey = '******';
    },
    async syncOrganizationUnits() {
      try {
        this.syncing = true;
        const response = await PlatformAPI.post(
          `/api/backends/${this.backend.id || ""}/sync`,
          {}
        );

        if (response.data && response.data.success) {
          this.setSnack({
            message: "Organization units succesfully synced",
            color: "success",
          });
        }
      } catch (err) {
        this.setSnack({
          message: "Error during synchronization",
          color: "error",
        });
      } finally {
        this.syncing = false;
      }
    },
  },
};
</script>
