<template>
  <v-card flat>
    <v-card-title v-if="ou.id">
      <i class="nbi-icon nbi-store" style="font-size: 4rem"></i>
      <p class="ml-3 mt-5">{{ ou.name }}</p>
    </v-card-title>

    <v-card-text>
      <p>
        This organization unit has <b>{{ ou.devices }}</b> devices configured of
        which <b>{{ ou.connected }}</b> are online.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import { PlatformAPI } from "../http";

export default {
  props: ["item"],
  data() {
    return {
      ou: {},
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.item) return;

      var response = await PlatformAPI.get(`/api/system/ou/${this.item.id}`);
      this.ou = response.data;
    },
  },
};
</script>