<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-form
          style="max-width: 550px"
          ref="loginForm"
          v-model="valid"
          @keyup.native.enter="valid && submit($event)"
        >
          <h2 class="mb-5">Login</h2>
          <v-card class="pa-5">
            <v-card-text>
              <v-text-field
                outlined
                v-model="user.emailAddress"
                name="login"
                label="Email address"
                type="text"
                required
                :rules="[(v) => !!v || 'Email is required']"
              ></v-text-field>
              <v-text-field
                id="password"
                outlined
                v-model="user.password"
                name="password"
                label="Password"
                type="password"
                required
                :rules="[(v) => !!v || 'Passsword is required']"
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-end ">
              <v-btn
                class="primary"
                outlined
                :disabled="!valid"
                @click.native="submit()"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>



<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      valid: false,
      user: {
        emailAddress: "",
        password: "",
      },
    };
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.user.emailAddress = "root@newblack.io";
      this.user.password = "1234";
    }
  },
  methods: {
    ...mapMutations(["setSnack"]),
    async submit() {
      if (this.$refs.loginForm.validate()) {
        this.$store.dispatch("authenticate", this.user).then(
          (user) => {
            if (user.token) {
              this.$router.push("/system");
            } else {
              this.setSnack({
                message: "Failed to authenticate",
                color: "error",
              });
            }
          },
          (err) => {
            this.setSnack({
              message: err,
              color: "error",
            });
          }
        );
      }
    },
  },
};
</script>
