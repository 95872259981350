<template>
  <v-card flat>
    <v-card-title>
      <i class="nbi-icon nbi-sentinel" style="font-size: 4rem"></i>
      <p class="ml-3 mt-5">{{ device.name }}</p>
      <v-spacer></v-spacer>
      <v-btn outlined color="secondary" class="v-btn-icon" v-if="!editing" @click="startEditor">
        <i class="nbi-icon nbi-edit"></i>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <div
        v-if="device.state"
        :class="stateColor(device)"
      >{{ device.state.charAt(0).toUpperCase() + device.state.slice(1) }}</div>
      <div v-if="device.lastConnected">Connected since {{ formatDateTime(device.lastConnected) }} </div>
      <div style="display: flex; align-items: center">
        <span ref="device-id">{{ device.id }}</span>
        <v-btn text icon @click="copyElementText('device-id')">
          <i class="nbi-icon nbi-duplicate small ml-1"></i>
        </v-btn>
      </div>
    </v-card-subtitle>

    <v-divider></v-divider>
    <div v-if="editing">
      <v-form ref="form" v-model="valid">
        <v-card-text>
          <v-text-field label="Name" outlined v-model="record.name" :rules="rules.name" required></v-text-field>
          <v-checkbox v-model="record.isFallback" label="Fallback station"></v-checkbox>
          <v-autocomplete
            label="Attached to"
            clearable
            outlined
            v-model="record.organizationUnitId"
            no-data-text="No Organization Units found"
            :items="organizationUnits"
            :disabled="record.isFallback"
            item-value="id"
            :item-text="formatOUName"
          ></v-autocomplete>

          <!-- <v-text-field
            :disabled="record.managementId != null"
            outlined
            label="Serial Number"
            v-model="record.serialNumber"
          ></v-text-field>

          <v-btn
            small
            @click="lookupExistingDevice"
            :disabled="record.managementId != null || !record.serialNumber"
            >Link Management ID</v-btn
          >-->

          <v-card-title>Optional Modules</v-card-title>
          <v-checkbox
          
            v-model="record.offlineEnabled"
            :disabled="!canOffline"
            label="Offline Functionality"
          ></v-checkbox>
          <v-text-field
            ref="offlineApiKey"
            outlined
            :type="showApiKey ? 'text' : 'password'"
            label="API Key"
            v-model="record.offlineApiKey"
            :disabled="!record.offlineEnabled"
            :rules="record.offlineEnabled ? rules.offlineApiKey : []"
            append-icon="nbi-icon nbi-duplicate"
            @click:append="copyElementText('offlineApiKey')"
          ></v-text-field>

          <v-btn
            small
            @click="showApiKey = !showApiKey"
            :disabled="!record.offlineApiKey"
            color="secondary"
            outlined
          >
            <i
              class="nbi-icon small"
              :class="{ 'nbi-eye': !showApiKey, 'nbi-eye-slash': showApiKey }"
            ></i>
            {{ showApiKey ? "Hide" : "Show" }} Key
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="record.id"
            class="v-btn-icon"
            color="secondary"
            outlined
            @click="deleteDevice"
          >
            <i class="nbi-icon nbi-trashcan error--text"></i>
          </v-btn>

          <v-col class="text-right">
            <v-btn class="ma-2" color="secondary" outlined @click="editing = false">Cancel</v-btn>
            <v-btn outlined class="primary ma-2" :disabled="!valid" @click="saveDevice">Save</v-btn>
          </v-col>
        </v-card-actions>
      </v-form>
    </div>
    <div v-else>
      <v-card-text>
        <v-row class="text-left">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Global</v-col>
          <v-col class="pa-1">{{ !(device.organizationUnitId || device.backendId)}}</v-col>
        </v-row>

        <v-row class="text-left">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Organization Unit</v-col>
          <v-col class="pa-1">{{ formatOUName(device.organizationUnit) }}</v-col>
        </v-row>
        <v-row class="text-left" v-if="device.organizationUnit">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Country</v-col>
          <v-col class="pa-1">{{ formatCountry(device.organizationUnit.countryId) }}</v-col>
        </v-row>

        <v-row class="text-left" v-if="device.backendInstanceId">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">ID</v-col>
          <v-col class="pa-1">{{ device.backendInstanceId }}</v-col>
        </v-row>

        <v-row class="text-left" v-if="device.serialNumber">
          <v-col class="text-left pa-1" cols="3" style="color: #6b757c">Serial Number</v-col>
          <v-col class="pa-1">
            <span ref="serialNumber">{{ device.serialNumber }}</span>
            <v-btn text icon @click="copyElementText('serialNumber')">
              <i class="nbi-icon nbi-duplicate small ml-1"></i>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col c class="text-left pa-1" cols="3" style="color: #6b757c">Management ID</v-col>
          <v-col class="pa-1">{{ device.managementId }}</v-col>
        </v-row>

        <v-row class="text-left">
          <v-col>
            <v-checkbox :ripple="false" readonly :input-value="device.offlineEnabled" label="Offline Functionality"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <confirm-dialog ref="confirm"></confirm-dialog>
  </v-card>
</template>
<script>
import { PlatformAPI } from "../http";
import { mapMutations } from "vuex";

export default {
  props: ["item", "backendId"],
  data() {
    return {
      device: {},
      record: {},
      editing: false,
      organizationUnits: [],
      valid: false,
      showApiKey: false,
      rules: {
        name: [v => !!v || "Name is required"],
        offlineApiKey: [v => !!v || "API Key is required"]
      }
    };
  },
  mounted() {
    this.loadData();
  },

  watch: {
    item() {
      console.log("item changed");
      this.loadData();
    }
  },
  computed: {
    canOffline() {
      return !this.record.isFallback && this.record.organizationUnit != null;
    }
  },
  methods: {
    ...mapMutations(["setSnack"]),
    copyElementText(el) {
      var e = this.$refs[el];

      navigator.clipboard.writeText(e.value || e.innerText).then(
        () => {
          this.setSnack({
            message: "Copied to clipboard",
            color: "success"
          });
        },
        err => {
          this.setSnack({
            message: "Error copying to clipboard : " + err,
            color: "error"
          });
        }
      );
    },
    startEditor() {
      this.record = { ...this.device };
      this.editing = true;
    },
    async saveDevice() {
      this.record.backendId = this.backendId;
      var response = await PlatformAPI.post(
        `/api/devices/${this.device.id}`,
        this.record
      );
      this.device = response.data;
      this.editing = false;
      this.$emit("device-updated");
    },
    stateColor(item) {
      //if (item.state == "relayed" && item.isAuthorized) return "blue--text";
      if (item.state == "connected") return "green--text";

      if (item.state == "identifying") return "yellow--text";
      // if (item.state == "disconnected") return "red--text";
      return null;
    },
    async lookupExistingDevice() {
      try {
        const response = await PlatformAPI.post(`/api/onboarding/lookup`, {
          serialNumber: this.record.serialNumber
        });

        if (response.status == 200) {
          this.device.managementId = response.data.id;
          this.device.created_ts = response.data.created_ts;
          this.device.status = response.data.status;
        }
      } catch (err) {
        if (err.response.status == 404)
          this.device = {
            serialNumber: this.device.serialNumber,
            managementId: null
          };
      } finally {
        this.lookupDone = true;
      }
    },

    async loadData() {
      if (!this.item) return;

      var response = await PlatformAPI.get(`/api/devices/${this.item.id}`);
      this.device = response.data;

      response = await PlatformAPI.get(`/api/ou`, {
        params: { backendId: this.backendId }
      });
      this.organizationUnits = response.data;
    },

    async deleteDevice() {
      const confirm = await this.$refs.confirm.open("Delete", "Are you sure?", {
        color: "red"
      });

      if (!confirm) return;

      try {
        var response = await PlatformAPI.delete(`/api/devices/${this.item.id}`);
        this.editing = false;

        this.setSnack({
          message: "Device deleted",
          color: "success"
        });
        this.$emit("device-deleted");
      } catch (e) {
        this.setSnack({
          message: e,
          color: "error"
        });
      }
    }
  }
};
</script>