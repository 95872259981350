<template>
  <v-layout align-center justify-center>
    <div style="max-width: 900px">
      <h2 class="mb-5">Welcome to EVA Sentinel.</h2>

      <v-card outlined class="mt-5">
        <v-card-title>Backend</v-card-title>
        <v-card-text v-if="!backendConnectionTest.success === true">
          <p>
            Configure the first backend (EVA) to allow Sentinel to talk to it
          </p>
        </v-card-text>
        <v-slide-y-transition>
          <BackendEditor
            v-if="!(backendConnectionTest.success === true)"
            :backend="backend"
            :allowDelete="false"
            v-on:backend-saved="backendSaved"
            :simple="true"
            buttonText="Configure"
          ></BackendEditor>
        </v-slide-y-transition>
        <v-card-text v-if="backendConfigured">
          <!-- <v-select
          v-if="backends.length>0"
          :items="backends"
          item-text="name"
          outlined
          label="Select your backend"
          v-model="backend"
          return-object
          @change="testAndSync"
        >
        </v-select> -->

          <p>
            <i class="v-icon nbi-icon nbi-check success--text mr-5"></i
            >Configured<span v-if="backend.id > 0"> {{ backend.name }}</span>
          </p>

          <p v-if="backendConnectionTest.success === true">
            <i class="v-icon nbi-icon nbi-check success--text mr-5"></i
            >Succesfully connected to
            <span v-if="backend.id > 0"> {{ backend.name }}</span>
          </p>
          <p v-if="backendConnectionTest.success === false">
            <i class="v-icon nbi-icon nbi-warning error--text mr-5"></i
            >Connection test to {{ backend.name }} failed :
            {{ backendConnectionTest.message }}
          </p>

          <p v-if="syncing">
            <v-progress-circular
              class="mr-5"
              :size="20"
              width="1"
              color="primary"
              indeterminate
            ></v-progress-circular>

            Synchronizing organization units with backend
          </p>

          <p v-if="!syncing && syncSuccess">
            <i class="v-icon nbi-icon nbi-check success--text mr-5"></i
            >Synchronized with backend
          </p>
          <p v-if="!syncing && !syncSuccess && syncError">
            <i class="v-icon nbi-icon nbi-warning warning--text mr-5"></i
            >{{ syncError }}
          </p>
        </v-card-text>
      </v-card>

      <v-card
        outlined
        class="mt-5"
        :disabled="!(backendConnectionTest.success === true) || !syncSuccess"
      >
        <v-card-title>Add your first device</v-card-title>
        <v-card-text
          >Add the first device to your EVA Sentinel environment. A device in
          the EVA Sentinel system represents a physical device that is placed in
          a location outside of the EVA Cloud platform
        </v-card-text>

        <OnboardDeviceWizard
          v-on:device-saved="deviceSaved"
          :device="device"
        ></OnboardDeviceWizard>
        <!-- <v-card-actions>
        <v-btn
          color="secondary"
          outlined
        >Add your first Device</v-btn>

      </v-card-actions> -->

        <!-- <v-card-text>
          <v-form ref="deviceForm" v-model="deviceValid" v-if="!canComplete">
            <v-text-field
              label="Name"
              outlined
              v-model="device.name"
              :rules="deviceRules.name"
              required
            ></v-text-field>
            <v-checkbox
              v-model="device.isFallback"
              label="Fallback station"
            ></v-checkbox>
            <v-autocomplete
              label="Attached to"
              clearable
              outlined
              v-model="device.organizationUnitId"
              no-data-text="No Organization Units found"
              :items="organizationUnits"
              :disabled="device.isFallback"
              item-value="id"
              :item-text="formatOUName"
            ></v-autocomplete>

            <v-text-field
              outlined
              label="Serial Number"
              v-model="device.serialNumber"
            ></v-text-field>
          </v-form>

          <p v-if="canComplete && device.id">
            <i class="v-icon nbi-icon nbi-check success--text mr-5"></i
            >Succesfully configured<span v-if="device.id">
              {{ device.name }}</span
            >
          </p>
        </v-card-text> -->
        <!-- <v-card-actions class="justify-end" v-if="!canComplete">
          <v-btn
            outlined
            class="primary ma-2"
            :disabled="!deviceValid"
            @click="saveDevice"
            >Configure</v-btn
          >
        </v-card-actions> -->
      </v-card>

      <v-card-actions class="justify-end px-0">
        <v-btn
          class="primary"
          outlined
          :disabled="!canComplete"
          @click="completeSetup"
          >Complete Setup</v-btn
        >
      </v-card-actions>
      <!-- 
        <p>It seems that you don't have any configured backends yet. Please start by configuring your first backend here</p>
        <i
          class="nbi-icon"
          :class="{'success--text': platform.backends>3, 'nbi-check': platform.backends>3, 'nbi-warning': platform.backends<3}"
        ></i>
        <p>After you have configured the backend connection, you can import the organization units from your backend connection</p>

        <i
          class="nbi-icon nbi-check"
          :class="{'success--text': platform.organizationUnits>0}"
        ></i>
        <i
          class="nbi-icon nbi-check"
          :class="{'success--text': platform.totalDevices>0}"
        ></i>
-->
    </div>
  </v-layout>
</template>


<script>
import { PlatformAPI } from "../http";

import BackendEditor from "./backend-editor";
import OnboardDeviceWizard from "./onboard-device-wizard";
const backendTemplate = function (seed) {
  return {
    name: null,
    id: 0,
    isEnabled: true,
    clientSecret: null,
    clientKey: null,
    ...seed,
  };
};

export default {
  components: {
    BackendEditor,
    OnboardDeviceWizard,
  },
  data() {
    return {
      platform: { backends: 1, organizationUnits: 0 },
      backendEditing: false,

      backends: [],
      backend: backendTemplate(
      //   {
      //   apiKey:
      //     "70AAB414A7A4AF08089B8A233D5197D3FA2450B332F0EB64900B176F6926FE8C",
      //   endPoint: "https://api.rituals.test.eva-online.cloud/",
      //   name: "test",
      // }
      ),
      backendConnectionTest: {},

      syncing: false,
      syncSuccess: false,
      syncError: null,

      organizationUnits: [],
      organizationUnit: {},

      device: {},
      deviceValid: false,
      deviceRules: {
        name: [(v) => !!v || "Name is required"],
      },
    };
  },
  async mounted() {
    await this.loadData();
    //this.backends = [];
  },
  computed: {
    backendConfigured() {
      return this.backend.id > 0;
    },
    ouConfigured() {
      return this.backend.id > 0 && this.organizationUnits.length > 0;
    },
    canComplete() {
      return this.ouConfigured && this.device.id;
    },
  },
  methods: {
    async deviceSaved(device) {
      this.device = device;
      this.device.id && this.completeSetup();
    },
    completeSetup() {
      this.$router.push({
        name: "system",
        params: { type: "device", id: this.device.id },
      });
    },
    async syncOrganizationUnits() {
      this.syncing = true;
      this.syncSuccess = false;

      try {
        const response = await PlatformAPI.post(
          `/api/backends/${this.backend.id || ""}/sync`,
          {}
        );
        this.syncSuccess = response.data.success;
        this.syncing = false;
        this.syncError = response.data.error;

        if (this.syncSuccess) {
          const ouResponse = await PlatformAPI.get("/api/ou", {
            params: { backendId: this.backend.id },
          });
          this.organizationUnits = ouResponse.data;
        }
      } catch (err) {
        this.syncSuccess = false;
        this.syncing = false;
        this.syncError = err;
        window.alert(err);
        console.log(err);
      }
    },

    async testAndSync() {
      await this.testConnection();

      if (this.backendConnectionTest.success)
        await this.syncOrganizationUnits();
    },
    async backendSaved(backend) {
      console.log(backend);

      this.bc = true;
      this.loadData();
      this.backend = backend;

      await this.testAndSync();

      this.backendEditing = !this.backendConnectionTest.success;

      await this.$store.dispatch("refreshPlatformInformation");
    },
    async testConnection() {
      const response = await PlatformAPI.post(
        `/api/backends/${this.backend.id}/test-connection`,
        {}
      );
      this.backendConnectionTest = response.data;
    },
    // async saveDevice() {
    //   var response = await PlatformAPI.post(`/api/devices/`, this.device);
    //   this.device = response.data;
    // },
    async loadData() {
      let response = await PlatformAPI.get("/api/info");
      this.platform = response.data;
      response = await PlatformAPI.get("/api/backends");

      this.backends = response.data;

      // this.loading = true;
      // PlatformAPI.get("/api/info").then((response) => {
      //   this.platform = response.data;
      // });
    },
  },
};
</script>