import VueRouter from 'vue-router'

import Login from './components/login';
import Backends from './components/backends';
import Backend from './components/backend';
import Users from './components/users';
import User from './components/user';
import Style from './components/style';
import DeviceTree from './components/device-tree';
import DeviceMap from './components/device-map';
import Settings from './components/settings';
import Setup from './components/setup';
import OnboardDevice from './components/onboard-device';

import store from './store'

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [{
      name: 'login',
      path: '/login',
      component: Login,
      meta: {
        fullScreen: true,
        navigationEnabled: false,
      }
    },

    {
      name: 'system',
      path: '/system/:type?/:id?',
      component: DeviceTree,
      meta: {
        title: 'Device Tree'
      },

    },
    {
      name: 'map',
      path: '/map',
      component: DeviceMap,
      meta: {
        title: 'Device Map'
      },

    },


    {
      path: '/devices/new',
      component: OnboardDevice,
      name: 'onboard-device'
    },

    // {
    //   path: '/devices/new',
    //   component: CreateDevice,
    //   name: 'create-device'
    // },

    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      redirect: '/system'
    },
    {
      path: '/settings',
      component: Settings,
      name: "settings"
    }, {
      path: '/setup',
      component: Setup,
      name: 'setup',
      meta: {
        fullScreen: true,
        navigationEnabled: false,
      }
    },

    {
      name: 'style',
      path: '/style',
      component: Style
    },


    {

      path: '/users',
      name: 'users',
      component: Users,
      meta: {
        title: 'Users'
      }
    },


    {
      name: 'user',
      path: '/users/:id',
      component: User,
      meta: {
        title: 'User',
        parents: ['users']
      }
    },
    {
      name: 'backends',
      path: '/backends/',
      component: Backends,
      meta: {
        title: 'Backends'
      }
    },
    {
      name: 'backend',
      path: '/backends/:id',
      component: Backend,
      meta: {
        title: 'Backend',
        parents: ['backends']
      },
    }
  ]
});





router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/style'];
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = store.state.authenticated_user && store.state.authenticated_user.token;
  if (authRequired && !loggedIn && to.path != '/login') {
    router.push({
      path: '/login'
    });
  }

  if (loggedIn && to.path != '/setup' && store.state.platform.backends < 1  && !store.state.offline ) {
    router.push({
      path: '/setup'
    });
  } else
    next();
})





export default router