<template>
  <div style="max-width: 1400px">
    <h2 class="mb-5">System Overview</h2>
    <v-card class="pb-5" outlined>
      <v-row class="pa-4" justify="space-between">
        <v-col cols="5" class="col-lg-4">
          <v-select
            v-if="backends.length > 1"
            outlined
            label="Backend"
            :items="backends"
            item-value="id"
            item-text="name"
            v-model="selectedBackendId"
            @change="refresh"
            append-icon="nbi-icon nbi-chevron-down"
          ></v-select>
          <v-text-field
            v-model="search"
            prepend-inner-icon="nbi-icon nbi-search"
            label="Filter..."
            flat
            outlined
            solo
            hide-details
            clearable
            clear-icon="nbi-icon nbi-xmark"
            v-on:input="searchChanged"
            class="mb-5 no-label"
          >
          </v-text-field>
          <v-divider></v-divider>
          <v-card-actions class="justify-end px-0 mt-2">
            <v-btn
              @click="collapseAll"
              outlined
              color="secondary"
              class="v-btn-icon"
            >
              <i
                class="nbi-icon"
                :class="{ 'nbi-expand': collapsed, 'nbi-collapse': !collapsed }"
              ></i>
            </v-btn>
            <v-btn
              outlined
              class="v-btn-icon"
              color="secondary"
              to="/devices/new"
            >
              <i class="nbi-icon nbi-plus"></i>
            </v-btn>

            <v-btn to="/map" outlined color="secondary" class="v-btn-icon">
              <i class="nbi-icon nbi-network"></i>
            </v-btn>
          </v-card-actions>
          <v-treeview
            ref="systemTree"
            :items="tree"
            :active.sync="active"
            :open.sync="open"
            return-object
            hoverable
            activatable
            item-key="id"
            transition
            :filter="treeFilter"
            :search="search"
            @update:active="activeChanged"
          >
            <template v-slot:prepend="{ item }">
              <i
                class="nbi-icon nbi-sentinel"
                v-if="item.type == 'device'"
                :class="stateColor(item)"
              ></i>
              <i
                class="nbi-icon nbi-folder-marker"
                v-if="item.type == 'country'"
              ></i>
              <i
                class="nbi-icon nbi-folder-star"
                v-if="item.type == 'fallback'"
              ></i>
              <i class="nbi-icon nbi-store" v-if="item.type == 'ou'"></i>
              <i
                class="nbi-icon nbi-folder"
                v-if="item.type == 'unassigned'"
              ></i>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col class="d-flex">
          <v-scroll-y-transition mode="out-in">
            <v-sheet
              v-if="!selected"
              class="grey--text text--lighten-1 font-weight-light"
            >
              Select a node in the treeview on the left so see its details. You
              can also use the filter textbox to lookup a specific device
            </v-sheet>
            <v-card v-else :key="selected.id" class="mx-auto" flat width="100%">
              <CountryEditorCard
                v-if="selected && selected.type == 'country'"
                v-bind:item="selected"
              ></CountryEditorCard>
              <DeviceEditorCard
                v-if="selected && selected.type == 'device'"
                v-bind:item="selected"
                :backendId="selectedBackendId"
                v-on:device-updated="deviceUpdated"
                v-on:device-deleted="deviceDeleted"
              ></DeviceEditorCard>
              <OrganizationEditorCard
                v-if="selected && selected.type == 'ou'"
                v-bind:item="selected"
              ></OrganizationEditorCard>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<style>
.v-treeview-node {
  cursor: pointer;
}
</style>

<script>
import { PlatformAPI, WSAPI } from "../http";
import CountryEditorCard from "./device-tree-country";
import OrganizationEditorCard from "./device-tree-organization-unit";
import DeviceEditorCard from "./device-tree-device";
import { HubConnectionBuilder } from "@microsoft/signalr";

const search = (parents, node, id, type) => {
  if (node.id === id && node.type === type) {
    return { node, parents };

    // als children null of undefined kan zijn moet hier nog een null check in
  } else if (node.children && node.children.length > 0) {
    let i;
    let result;
    for (i = 0; result == null && i < node.children.length; i++) {
      result = search(parents.concat(node), node.children[i], id, type);
    }
    return result;
  }

  return null;
};

export default {
  components: {
    CountryEditorCard,
    OrganizationEditorCard,
    DeviceEditorCard,
  },
  data() {
    return {
      connection: null,
      tree: [],
      selectedBackendId: null,
      active: [],
      open: [],
      search: "",
      caseSensitive: false,
      collapsed: true,

      backends: [],
    };
  },
  mounted() {
    this.refresh();
    if (this.$route.query.q) {
      setTimeout(() => {
        this.search = this.$route.query.q;
      }, 500);
    }

    var self = this;

    this.connection = new HubConnectionBuilder()
      .withUrl(WSAPI.url() + `/ws/${WSAPI.version()}/ui`, {
        accessTokenFactory: () => self.$store.state.authenticated_user.token,
      })
      .withAutomaticReconnect()
      .build();

    this.connection.on("DeviceUpdate", (data) => self.updateNode(data));

    this.connection.start().then(() => console.log("connected"));
  },

  beforeDestroy() {
    this.connection && this.connection.stop();
  },
  computed: {
    treeFilter() {
      return (item, search, textKey) => {
        return (
          item[textKey].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          (item.backendInstanceId &&
            item.backendInstanceId.indexOf(search) > -1) ||
          (item.serialNumber && item.serialNumber.indexOf(search) > -1) ||
          (item.managementId && item.managementId.indexOf(search) > -1) ||
          (item.id && item.id.indexOf(search) > -1)
        );
      };
    },

    selected() {
      return this.active.length && this.active[0];
    },
  },

  methods: {
    test() {
      this.setActiveNode("ou", "2");
    },
    updateNode(data) {
      var { node } = search(
        [],
        { id: null, children: this.tree },
        data.id,
        "device"
      );

      if (node) {
        node.state = data.state;
        if (this.selected === node) {
          this.active[0].state = data.state;
          console.log(this.active[0]);
        }
      }
    },
    activeChanged() {
      if (
        this.$route.params["type"] != this.selected.type ||
        this.$route.params["id"] != this.selected.id
      ) {
        const route = this.$router.resolve({
          name: "system",
          params: { type: this.selected.type, id: this.selected.id },
        });
        history.replaceState(null, "", route.href);
      }
      this.selected.type && this.selected.id && this.setActiveNode(this.selected.type, this.selected.id);
    },
    deviceUpdated() {
      this.refresh();
    },
    deviceDeleted() {
      this.active = [];

      const route = this.$router.resolve({
        name: "system",
      });
      history.replaceState(null, "", route.href);
      this.refresh();
    },
    searchChanged(val) {
      this.$refs.systemTree.updateAll(this.search);
    },
    collapseAll() {
      this.collapsed = !this.collapsed;
      this.$refs.systemTree.updateAll(!this.collapsed);
    },
    setActiveNode(type, id) {
      var { node, parents } = search(
        [],
        { id: null, children: this.tree },
        id,
        type
      );
      if (node) this.active = [node];
      if (parents) this.open = parents.concat(node);
    },

    stateColor(item) {
      //if (item.state == "relayed" && item.isAuthorized) return "blue--text";
      if (item.state == "connected") return "green--text";

      if (item.state == "identifying") return "yellow--text";
      // if (item.state == "disconnected") return "red--text";
      return null;
    },
    refresh() {
      try {
        this.loading = true;

        PlatformAPI.get("/api/backends").then((response) => {
          if (response && response.data) this.backends = response.data;
          if (this.backends.length && this.selectedBackendId == null)
            this.selectedBackendId = this.backends[0].id;

          PlatformAPI.get(`/api/system/overview`, {
            params: { backendId: this.selectedBackendId },
          }).then(
            (response) => {
              if (response && response.data) this.tree = response.data;
              if (this.tree) {
                if (this.$route.params["type"] && this.$route.params["id"]) {
                  this.setActiveNode(
                    this.$route.params["type"],
                    this.$route.params["id"]
                  );
                }
              }
            },
            () => {
              this.tree = null;
            }
          );
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>