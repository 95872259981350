import Vue from 'vue'
import vuetify from './plugins/vuetify'

import App from '@/app.vue'
import VueRouter from 'vue-router'
import axios from 'axios'

Vue.config.productionTip = false

Vue.use(VueRouter);

import router from './router';
import store from './store';

Vue.prototype.$http = axios

import ConfirmDialog from '@/components/confirm-dialog';
Vue.component('confirm-dialog', ConfirmDialog);

import Formatters from '@/mixins/formatters';
Vue.mixin(Formatters);


// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import 'leaflet/dist/leaflet.css';

// Vue.component('l-map', LMap);
// Vue.component('l-tile-layer', LTileLayer);
// Vue.component('l-marker', LMarker);



store.dispatch('refreshLookups');
store.dispatch('refreshPlatformInformation').then(x => {

  new Vue({
    vuetify,
    router,
    store,

    render: h => h(App),
  }).$mount('#app');

});