import Vuex from 'vuex';
import Vue from 'vue';
import {
  PlatformAPI,
  AuthenticationPlugin
} from '../http';

import pjson from '@/../package.json';

Vue.use(Vuex);


const navItems = [{
    icon: "nbi-overview",
    text: "System",
    route: "/system"
  },
  {
    icon: "nbi-eva",
    text: "Backends",
    route: "/backends"
  },
  {
    icon: "nbi-user",
    text: "Users",
    route: "/users"
  },
  {
    icon: "nbi-gear",
    text: "Settings",
    route: "/settings"
  },


];
const state = {
  version: process.env.NODE_ENV == 'development' ? 'develop' : pjson.version,
  environment: process.env.NODE_ENV,
  countries: [],
  platform: {
    backends: 0
  },
  authenticated_user: null,
  snackbar: {
    message: '',
    color: 'success'
  },
  offline: false,
}

const store = new Vuex.Store({
  plugins: [AuthenticationPlugin],
  state,
  getters: {
    navigationItems: state => {
      return state.authenticated_user ? navItems : [];
    }
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setLoggedInUser(state, payload) {
      state.authenticated_user = payload;
      if (state.authenticated_user)
        localStorage.setItem('user', JSON.stringify(payload));
      else
        localStorage.removeItem('user');
    },
    setPlatformInformation(state, info) {
      state.platform = info;
    },
    setSnack(state, {
      message,
      color
    }) {
      state.snackbar = {
        message,
        color
      };
    },
  },
  actions: {
    logout({
      commit
    }) {
      commit('setLoggedInUser');
    },
    async authenticate({
      commit,
    }, user) {

      var response = await PlatformAPI.post(`/api/users/authenticate`, user)
      if (response.data) {
        commit('setLoggedInUser', response.data);
        await this.dispatch('refreshPlatformInformation');
        return response.data;
      } else {
        return null;
      }
    },

    async refreshPlatformInformation({
      commit
    }) {

      try {
        const loggedIn = this.state.authenticated_user && this.state.authenticated_user.token;
        if (!loggedIn)
          return;

        var platformResponse = await PlatformAPI.get(`/api/info`);
        if (platformResponse && platformResponse.data) {
          commit('setPlatformInformation', platformResponse.data);
          return platformResponse.data;
        }
        return null;

      } catch (error) {
        console.log(error);
      }
    },
    async refreshLookups({
      commit
    }) {

      var tasks = [];

      tasks.push(PlatformAPI.get(`/api/dd/countries`));

      return Promise.all(
        tasks
      ).then(results => {
        const [countries] = results;
        if (countries)
          commit('setCountries', countries.data);
      }, error => {
        console.log(error);
      });

    },
  }
})

var loggedInUser = {};
try {
  loggedInUser = JSON.parse(localStorage.getItem('user'));
  store.commit('setLoggedInUser', loggedInUser)
} catch (e) {
  store.commit('setLoggedInUser', null)
}


export default store;