<template>
  <div style="max-width: 900px">
    <h2 class="mb-5">
      Backends
      <v-btn
        outlined
        class="v-btn-icon float-right"
        color="secondary"
        :to="{ name: 'backend', params: { id: 'new' } }"
      >
        <i class="nbi-icon nbi-plus"></i>
      </v-btn>
    </h2>

    <v-card class="mb-5" outlined v-for="backend in backends" v-bind:key="backend.id">
      <v-toolbar flat color="white">
        <v-toolbar-title>{{ backend.name }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          outlined
          class="v-btn-icon"
          color="secondary"
          :to="{ name: 'backend', params: { id: backend.id } }"
        >
          <i class="nbi-icon nbi-edit"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row class="text-left">
          <v-col class="text-left" cols="3" style="color: #6b757c">Enabled</v-col>
          <v-col>
            <span v-if="backend.isEnabled" class="success--text">Yes</span>
            <span v-else class="secondary--text">No</span>
          </v-col>
        </v-row>
        <v-row class="text-left">
          <v-col class="text-left" cols="3" style="color: #6b757c">Endpoint</v-col>
          <v-col>{{ backend.endPoint }}</v-col>
        </v-row>

        <v-row class="text-left">
          <v-col class="text-left" cols="3" style="color: #6b757c">Number of devices</v-col>
          <v-col>{{ backend.devices }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-skeleton-loader type="card-heading,list-item" v-if="loading"></v-skeleton-loader>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>


<script>
import { PlatformAPI } from "../http";
export default {
  data() {
    return {
      backends: [],
      loading: false
    };
  },

  async mounted() {
    await this.getBackends();
  },

  methods: {
    async getBackends() {
      try {
        this.loading = true;
        const response = await PlatformAPI.get(`/api/backends`);
        if (response && response.data) this.backends = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
